import React, { lazy, Suspense } from "react";
import { createRoot } from "react-dom/client";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./i18n/config";
import MainContextProvider from "./context/context-provider";
import Loading from "./features/loading";
import App from "./App"

const root = createRoot(document.getElementById("root"));
root.render(
  <MainContextProvider>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </MainContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
