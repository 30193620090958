import React, { createContext, useContext, useState } from "react";

export const AuthPopupContext = createContext(null);

export const AuthPopupProvider = ({ children }) => {
  const [authPopups, setAuthPopups] = useState({});

  const handleAuthPopups = (key) => {
    // setAuthPopups((prev) => ({ ...prev, [key]: !authPopups[key] }));
    setAuthPopups((prev) => ({ [key]: !authPopups[key] }));
  };

  const getAuthPopups = (key) => {
    try {
      return authPopups[key] || false;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const allPopupsClose = () => {
    setAuthPopups({});
  };

  return (
    <AuthPopupContext.Provider
      value={{
        handleAuthPopups,
        authPopups,
        getAuthPopups,
        allPopupsClose,
      }}
    >
      {children}
    </AuthPopupContext.Provider>
  );
};

export const useAuthPopups = () => {
  const context = useContext(AuthPopupContext);
  return context;
};
