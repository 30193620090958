import React, { createContext, useContext, useState } from "react";

// Create the context
export const VideoContext = createContext();

// Create a context provider component
export const VideoProvider = ({ children }) => {
  const [video, setVideo] = useState(null);

  const setVideoData = async (video) => {
    setVideo(video);
  };

  const getVideoData = () => {
    return video;
  };

  const contextValues = {
    setVideoData,
    getVideoData,
  };

  return (
    <VideoContext.Provider value={contextValues}>
      {children}
    </VideoContext.Provider>
  );
};

// Create a custom hook for using the context
export const useVideo = () => {
  const context = useContext(VideoContext);
  return context;
};
