import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client'; // Import Socket.IO client
import pollsService from "../../services/polls/polls-service.js";
import { WEBSOCKET_URL } from '../../global/constants/app-constants.js';
import { useParams } from 'react-router-dom';

const Polls = () => {
  const { id } = useParams(); // Get pollId from URL parameters
  const [poll, setPoll] = useState({ question: '', choices: [] });
  const [selectedChoice, setSelectedChoice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [hasVoted, setHasVoted] = useState(false); // State to track if the user has voted

  // Fetch Poll from API
  useEffect(() => {
    const fetchPoll = async () => {
      try {
        setIsLoading(true);
        const response = await pollsService.getPollsById(id); // Adjust API endpoint
        setPoll(response.data);
      } catch (err) {
        setError('Error fetching poll.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchPoll();
  }, [id]);

  // Handle Voting Submission
  const handleVoteSubmit = async (e) => {
    e.preventDefault();

    if (selectedChoice === null) {
      setError('Please select an option.');
      return;
    }

    try {
      setIsLoading(true);
      const response = await pollsService.submitPollByPollId(poll._id, { choice: selectedChoice });
      setSuccessMessage('Thank you for voting!');
      setError('');
      setHasVoted(true); // Mark as voted after successful submission
    } catch (err) {
      setError('Error submitting vote.');
    } finally {
      setIsLoading(false);
    }
  };

  // Poll Rendering
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center md:p-4">
      {isLoading ? (
        <p className="text-lg text-center">Loading poll...</p>
      ) : error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : (
        <div className="w-full h-full max-w-2xl bg-white shadow-md rounded-lg p-2">
          <h1 className="text-[7px] md:text-sm font-bold mb-1 md:mb-2 text-center">{poll.question}</h1>

          {/* Poll Choices */}
          <div className="mb-1 md:mb-2 grid grid-cols-1 gap-1 md:gap-2">
            {poll.choices.map((choice, index) => (
              <label
                key={index}
                className={`block p-1 border-2 rounded-lg text-[7px] md:text-sm cursor-pointer transition-all ${selectedChoice === index ? 'border-blue-500 bg-blue-100' : 'border-gray-200'
                  }`}
              >
                <input
                  type="radio"
                  className="hidden"
                  value={index}
                  onChange={() => setSelectedChoice(index)}
                  checked={selectedChoice === index}
                  disabled={hasVoted} // Disable radio buttons if the user has voted
                />
                {choice.option}
              </label>
            ))}
          </div>

          {/* Submit Button */}
          <button
            className="w-full text-[7px] md:text-sm bg-blue-500 text-white py-1 rounded-lg hover:bg-blue-600 transition-colors disabled:bg-gray-300"
            onClick={handleVoteSubmit}
            disabled={isLoading || hasVoted} // Disable button if the user has voted or if loading
          >
            {hasVoted ? 'Vote Submitted' : 'Submit Vote'}
          </button>

          {/* Success Message */}
          {successMessage && <p className="text-green-500 text-[9px] md:text-sm text-center mt-2">{successMessage}</p>}
        </div>

      )}
    </div>
  );
};

export default Polls;
