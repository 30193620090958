import { getRequestMethod, postRequestMethod } from "../../global/api.manager";
import { POLLS_API } from "../../global/constants/api-constants";

const getPollsById = async (poll_id) => {
  try {
    const response = await getRequestMethod(
      `${POLLS_API}/${poll_id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const submitPollByPollId = async (poll_id, payload) => {
  try {
    const response = await postRequestMethod(
      `${POLLS_API}/${poll_id}/submit`, payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Object containing newsletter service functions.
 * @typedef {Object} LiveQuestionService
 * @property {Function} getPollsById - Function to add live question
 */

/**
 * @type {LiveQuestionService}
 */
const pollsService = {
  getPollsById,
  submitPollByPollId
};

export default pollsService;
