import { getAPIResponseError } from "../helper/common";
import AXIOS_INSTANCE from "./axios.js";

/**
 * Performs a GET request to the specified URL.
 *
 * @async
 * @function
 * @param {string} url - The URL for the GET request.
 * @param {Object} [config] - Optional configuration for the request.
 * @returns {Promise<Object>} A promise that resolves with the response data.
 * @throws {Error} Throws an error if there is an issue with the GET request or if the response status is 404.
 */
export const getRequestMethod = async (url, config) => {
  try {
    if (!url) {
      throw new Error("URL must be provided");
    }

    let response = await AXIOS_INSTANCE.get(url, config);

    if (response.status === 404) {
      throw new Error("Resource not found");
    }

    return response;
  } catch (e) {
    console.error(e);
    throw getAPIResponseError(e) || "Unable to fetch data, please try again";
  }
};

/**
 * Performs a POST request to the specified URL.
 *
 * @async
 * @function
 * @param {string} url - The URL for the POST request.
 * @param {Object} payload - The data to be sent in the POST request.
 * @param {Object} [config] - Optional configuration for the request.
 * @returns {Promise<Object>} A promise that resolves with the response data.
 * @throws {Error} Throws an error if there is an issue with the POST request.
 */
export const postRequestMethod = async (url, payload, config) => {
  try {
    if (!url) throw new Error("URL must be provided");
    if (!payload) throw new Error("Payload must be provided");

    let response = await AXIOS_INSTANCE.post(url, payload, config);
    return response;
  } catch (e) {
    throw getAPIResponseError(e) || "Unable to create data, please try again";
  }
};

/**
 * Performs a PUT request to the specified URL.
 *
 * @async
 * @function
 * @param {string} url - The URL for the PUT request.
 * @param {Object} payload - The data to be sent in the PUT request.
 * @param {Object} [config] - Optional configuration for the request.
 * @returns {Promise<Object>} A promise that resolves with the response data.
 * @throws {Error} Throws an error if there is an issue with the PUT request.
 */
export const putRequestMethod = async (url, payload, config) => {
  try {
    if (!url) throw new Error("URL must be provided");
    if (!payload) throw new Error("Payload must be provided");

    let response = await AXIOS_INSTANCE.put(url, payload, config);
    return response;
  } catch (e) {
    throw getAPIResponseError(e) || "Unable to create data, please try again";
  }
};

/**
 * Performs a DELETE request to the specified URL.
 *
 * @async
 * @function
 * @param {string} url - The URL for the DELETE request.
 * @param {Object} [config] - Optional configuration for the request.
 * @returns {Promise<Object>} A promise that resolves with the response data.
 * @throws {Error} Throws an error if there is an issue with the DELETE request.
 */
export const deleteRequestMethod = async (url, config) => {
  try {
    if (!url) throw new Error("URL must be provided");

    let response = await AXIOS_INSTANCE.delete(url, config);
    return response;
  } catch (e) {
    throw getAPIResponseError(e) || "Unable to delete data, please try again";
  }
};

/**
 * Performs a POST request with file upload to the specified URL.
 *
 * @async
 * @function
 * @param {string} url - The URL for the POST request.
 * @param {Object} payload - The data to be sent in the POST request.
 * @returns {Promise<Object>} A promise that resolves with the response data.
 * @throws {Error} Throws an error if there is an issue with the POST request.
 */
export const postRequestMethodForFile = async (url, payload) => {
  try {
    if (!url) throw new Error("URL must be provided");
    if (!payload) throw new Error("Payload must be provided");

    let headers = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    };

    let response = await AXIOS_INSTANCE.post(url, payload, {
      headers,
      onUploadProgress: (ProgressEvent) => {
        let progress =
          Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) + "%";
        return progress;
      },
    });
    return response;
  } catch (e) {
    throw getAPIResponseError(e) || "Unable to create data, please try again";
  }
};
