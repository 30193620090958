import axios from "axios";
import { API_BASE_URL } from "./constants/app-constants";
import { getAuthToken } from "../helper/local-storage/local-storage-helper";

const AXIOS_INSTANCE = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
AXIOS_INSTANCE.interceptors.request.use(
  (config) => {
    const token = getAuthToken(); // Replace with your actual token
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default AXIOS_INSTANCE;
