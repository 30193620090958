import React, { createContext, useContext, useEffect, useState } from "react";
import { COUNTRY_DETECTION_API } from "../global/constants/api-constants";
import i18n from "i18next";
import { LanguageContext } from "./language-context";
import { I18_NEXT_LANGUAGE_KEY } from "../global/constants/app-constants";
import { getUserPreferredLanguage, getUserDetails } from "../helper/local-storage/local-storage-helper";
import countriesUtility from "../utils/countries-utility"

// Create the context
export const UserDataContext = createContext();

// Create a context provider component
export const UserCountryDataProvider = ({ children }) => {
  const { setUserLanguage } = useContext(LanguageContext);

  const user = getUserDetails();

  const [countryData, setCountryData] = useState({
    country: "IN",
  });

  useEffect(() => {
    
     // Retrieve and parse the user preferred language
     let preferredLanguage = getUserPreferredLanguage();
     let language = preferredLanguage ? preferredLanguage : "en";

     // Change the language in i18n and update the user language state
     i18n.changeLanguage(language);
     setUserLanguage(language);

     // Store the language preference in local storage
     localStorage.setItem(I18_NEXT_LANGUAGE_KEY, language);

     // Set the text direction based on the language
     if (language === "ar") {
       document.getElementById("main-root").setAttribute("dir", "rtl");
     }

    // Make API call to get user's IP and country name for not logged in user only
    if (!user) {
      fetch(COUNTRY_DETECTION_API)
      .then((response) => response.json())
      .then((data) => {

        if (!data.country) {
          throw new Error("Missing expected 'country' field");
        }

        // Save the API response in context
        setCountryData(data);

      })
      .catch((error) => console.error("Error fetching user data:", error));

    }else{
      let country = countriesUtility.getCountryISOCode(user.country_details.dialing_code);
      setCountryData({country : country});
    }
  }, []); // Empty dependency array to run effect only once

  const contextValues = {
    countryData,
  };

  return (
    <UserDataContext.Provider value={contextValues}>
      {children}
    </UserDataContext.Provider>
  );
};

// Create a custom hook for using the context
export const useUserData = () => {
  const context = useContext(UserDataContext);
  return context;
};
