import React, { createContext, useContext, useState } from "react";

// Create the context
export const ChannelContext = createContext();

// Create a context provider component
export const ChannelProvider = ({ children }) => {
  const [channel, setChannel] = useState(null);

  const setChannelData = async (channel) => {
    setChannel(channel);
  };

  const getChannelData = () => {
    return channel;
  };

  const contextValues = {
    setChannelData,
    getChannelData,
  };

  return (
    <ChannelContext.Provider value={contextValues}>
      {children}
    </ChannelContext.Provider>
  );
};

// Create a custom hook for using the context
export const useChannel = () => {
  const context = useContext(ChannelContext);
  return context;
};
