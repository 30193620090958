import React, { useEffect, useRef, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import datalabels plugin
import { useParams } from 'react-router-dom';
import pollsService from '../../services/polls/polls-service';

Chart.register(...registerables, ChartDataLabels); // Register the datalabels plugin

const Results = () => {
    const { id } = useParams();
    const [pollData, setPollData] = useState(null);
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        const fetchPollData = async () => {
            try {
                const response = await pollsService.getPollsById(id);
                const data = response.data;

                if (data && data.choices) {
                    setPollData(data);
                }
            } catch (error) {
                console.error("Error fetching poll data:", error);
            }
        };

        fetchPollData();

        const intervalId = setInterval(() => {
            fetchPollData();
        }, 5000);

        return () => clearInterval(intervalId);
    }, [id]);

    useEffect(() => {
        if (pollData && chartRef.current) {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy(); // Destroy previous instance if exists
            }

            chartInstanceRef.current = new Chart(chartRef.current, {
                type: 'pie',
                data: {
                    labels: pollData.choices.map((choice, index) => `Option ${index + 1}`),
                    datasets: [
                        {
                            label: 'Poll Results',
                            data: pollData.choices.map(choice => choice.votes),
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.6)',
                                'rgba(54, 162, 235, 0.6)',
                                'rgba(255, 206, 86, 0.6)',
                                'rgba(75, 192, 192, 0.6)',
                            ],
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'bottom',
                        },
                        tooltip: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    const totalVotes = pollData.choices.reduce((acc, choice) => acc + choice.votes, 0);
                                    const currentVotes = pollData.choices[tooltipItem.dataIndex].votes;
                                    const percentage = ((currentVotes / totalVotes) * 100).toFixed(2);
                                    return `${pollData.choices[tooltipItem.dataIndex].option}: ${currentVotes} votes (${percentage}%)`;
                                },
                            },
                        },
                        datalabels: {
                            formatter: (value, context) => {
                                const totalVotes = pollData.choices.reduce((acc, choice) => acc + choice.votes, 0);
                                const percentage = ((value / totalVotes) * 100).toFixed(2);
                                return `${percentage}%`;
                            },
                            color: '#fff', // Label color
                            font: {
                                weight: 'bold',
                            },
                        },
                    },
                },
            });
        }

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [pollData]);

    return (
        <>
            {pollData && (
                <div className="flex flex-col items-center p-6 bg-white shadow-md rounded-lg">
                    <div className="mb-6">
                        <img
                            src="/docfliq-logo.png"
                            alt="Docfliq Logo"
                            className="w-24 h-auto"
                        />
                    </div>
                    <h2 className="text-2xl font-bold mb-2">Poll Results</h2>
                    <p className="text-lg mb-4">{pollData?.question}</p>
                    <div className="w-full mb-4">
                        <h3 className="text-lg font-semibold mb-2">Options:</h3>
                        <ul className="list-none p-0">
                            {pollData.choices.map((choice, index) => (
                                <li key={index} className="mb-2 bg-gray-100 p-2 rounded shadow">
                                    Option {index + 1} - {choice.option} - {choice.votes} votes
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="w-80 h-80 mb-4">
                        <canvas ref={chartRef} />
                    </div>
                </div>
            )}
        </>
    );
};

export default Results;
