import { useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { I18_NEXT_LANGUAGE_KEY } from "./global/constants/app-constants.js";
import Loading from "./features/loading/index.js";
import Polls from "./pages/polls/index.js";
import PollResults from "./pages/polls/results.js";
import TestTheoplayer from "./pages/test-theoplayer/index.js";

const Popups = lazy(() => import("./features/auth/popups.js"));
const Corporate = lazy(() => import("./pages/corporate/index.js"));
const Main = lazy(() => import("./pages/main/main.js"));
const BottomToTopButton = lazy(() => import("./features/bottom-to-top-button/bottom-to-top-button.js"));
function App() {
  useEffect(() => { }, []);

  // const location = useLocation();

  // // If the route is "/test-theoplayer", do not show the fallback
  // const noFallbackRoutes = ['/test-theoplayer'];

  return (
    <div id="main-root" dir={`${localStorage.getItem(I18_NEXT_LANGUAGE_KEY) === "ar" ? "rtl" : "ltr"}`}>
      <Router data-testid="router">
        <Suspense>
          <Routes>
            <Route path="/*" element={<Main />} />
            <Route path="/polls/:id" exact element={<Polls />} />
            <Route path="/polls-result/:id" exact element={<PollResults />} />
            <Route path="/test-theoplayer" exact element={<TestTheoplayer />} />
            <Route path="/corporate/*" element={<Corporate />} />
          </Routes>
          <Popups />
          <ToastContainer />
          <BottomToTopButton />
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
