const convertISTTimestampToUTCTimestamp = (timestamp) => {
  // Parse the input timestamp string
  const [dateString, timeString] = timestamp.split("T");
  const [year, month, day] = dateString.split("-");
  const [hours, minutes] = timeString.split(":").map(Number);

  // Create a Date object representing the input timestamp in IST
  const istDate = new Date(year, month - 1, day, hours, minutes);

  // Get the UTC time in milliseconds
  const utcTime = istDate.getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000); // IST is UTC+5:30

  // Create a new Date object with the UTC time
  const utcDate = new Date(utcTime);

  // Format the UTC date and time
  const formattedDate = utcDate.toISOString().split("T")[0];
  const formattedTime = utcDate.toTimeString().split(" ")[0];

  return `${formattedDate}T${formattedTime}`;
};

const convertUTCTimestampToUserTimezone = (utcTimestamp, targetTimezone) => {
  // Parse the UTC timestamp string
  const [dateString, timeString] = utcTimestamp.split("T");
  const [year, month, day] = dateString.split("-");
  const [hours, minutes, seconds] = timeString.split(":").map(Number);

  // Create a Date object representing the UTC timestamp
  const utcDate = new Date(
    Date.UTC(year, month - 1, day, hours, minutes, seconds)
  );

  // Format the date and time in the target timezone
  const options = {
    timeZone: targetTimezone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour12: false, // Use 24-hour format
    hour: "2-digit",
    minute: "2-digit",
  };
  const formattedDateTime = utcDate.toLocaleString("en-US", options);

  // Extract year, month, date, hours, and minutes
  const test = formattedDateTime.split(",");

  const completeDate = test[0].split("/");
  const completeTime = test[1].trim();

  const formattedDateTime1 = `${completeDate[2]}-${completeDate[0]}-${completeDate[1]}T${completeTime}`;

  return formattedDateTime1; // Return the formatted date and time
};

const convertISTTimestampToUserTimezone = (timestamp, targetTimezone) => {
  const utcTimestamp = convertISTTimestampToUTCTimestamp(timestamp);
  const convertedTimestamp = convertUTCTimestampToUserTimezone(
    utcTimestamp,
    targetTimezone
  );

  return convertedTimestamp;
};

const dateUtility = {
  convertISTTimestampToUTCTimestamp,
  convertUTCTimestampToUserTimezone,
  convertISTTimestampToUserTimezone,
};

export default dateUtility;
