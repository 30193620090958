const getCountryISOCode = (dialing_code) => {
  try {
    const country = countries.find(
      (element) => element.dialCode === dialing_code
    );
    return country ? country.code : null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const getCountryDialingCode = (code) => {
  try {
    const country = countries.find((element) => element.code === code);
    return country ? country.dialCode : "91";
  } catch (e) {
    return "91";
  }
};

const getCountryName = (code) => {
  try {
    const country = countries.find((element) => element.code === code);
    return country ? country.name : "INDIA";
  } catch (e) {
    return "INDIA";
  }
};

const getCountryTimezones = (code) => {
  let tz = countriesWithTimezones[code];
  if (tz?.length > 0) {
    return tz[0];
  }
  return tz;
};

const getCountryTimezonesAbbr = (code) => {
  if (code) {
    let abbr = countriesWithTimezonesAbbr[code];
    if (abbr.length > 1) {
      return abbr[0];
    } else {
      return abbr;
    }
  }
};

const countries = [
  {
    name: "Afghanistan",
    flag: "🇦🇫",
    code: "AF",
    dialCode: "93",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Åland Islands",
    flag: "🇦🇽",
    code: "AX",
    dialCode: "358",
    minLength: 15,
    maxLength: 15,
  },
  {
    name: "Albania",
    flag: "🇦🇱",
    code: "AL",
    dialCode: "355",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Algeria",
    flag: "🇩🇿",
    code: "DZ",
    dialCode: "213",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "American Samoa",
    flag: "🇦🇸",
    code: "AS",
    dialCode: "1684",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Andorra",
    flag: "🇦🇩",
    code: "AD",
    dialCode: "376",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Angola",
    flag: "🇦🇴",
    code: "AO",
    dialCode: "244",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Anguilla",
    flag: "🇦🇮",
    code: "AI",
    dialCode: "1264",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Antarctica",
    flag: "🇦🇶",
    code: "AQ",
    dialCode: "672",
    minLength: 6,
    maxLength: 6,
  },
  {
    name: "Antigua and Barbuda",
    flag: "🇦🇬",
    code: "AG",
    dialCode: "1268",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Argentina",
    flag: "🇦🇷",
    code: "AR",
    dialCode: "54",
    minLength: 12,
    maxLength: 12,
  },
  {
    name: "Armenia",
    flag: "🇦🇲",
    code: "AM",
    dialCode: "374",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Aruba",
    flag: "🇦🇼",
    code: "AW",
    dialCode: "297",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Australia",
    flag: "🇦🇺",
    code: "AU",
    dialCode: "61",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Austria",
    flag: "🇦🇹",
    code: "AT",
    dialCode: "43",
    minLength: 13,
    maxLength: 13,
  },
  {
    name: "Azerbaijan",
    flag: "🇦🇿",
    code: "AZ",
    dialCode: "994",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Bahamas",
    flag: "🇧🇸",
    code: "BS",
    dialCode: "1242",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Bahrain",
    flag: "🇧🇭",
    code: "BH",
    dialCode: "973",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Bangladesh",
    flag: "🇧🇩",
    code: "BD",
    dialCode: "880",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Barbados",
    flag: "🇧🇧",
    code: "BB",
    dialCode: "1246",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Belarus",
    flag: "🇧🇾",
    code: "BY",
    dialCode: "375",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Belgium",
    flag: "🇧🇪",
    code: "BE",
    dialCode: "32",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Belize",
    flag: "🇧🇿",
    code: "BZ",
    dialCode: "501",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Benin",
    flag: "🇧🇯",
    code: "BJ",
    dialCode: "229",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Bermuda",
    flag: "🇧🇲",
    code: "BM",
    dialCode: "1441",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Bhutan",
    flag: "🇧🇹",
    code: "BT",
    dialCode: "975",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Bolivia, Plurinational State of bolivia",
    flag: "🇧🇴",
    code: "BO",
    dialCode: "591",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Bosnia and Herzegovina",
    flag: "🇧🇦",
    code: "BA",
    dialCode: "387",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Botswana",
    flag: "🇧🇼",
    code: "BW",
    dialCode: "267",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Bouvet Island",
    flag: "🇧🇻",
    code: "BV",
    dialCode: "47",
    minLength: 15,
    maxLength: 15,
  },
  {
    name: "Brazil",
    flag: "🇧🇷",
    code: "BR",
    dialCode: "55",
    minLength: 11,
    maxLength: 11,
  },
  {
    name: "British Indian Ocean Territory",
    flag: "🇮🇴",
    code: "IO",
    dialCode: "246",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Brunei Darussalam",
    flag: "🇧🇳",
    code: "BN",
    dialCode: "673",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Bulgaria",
    flag: "🇧🇬",
    code: "BG",
    dialCode: "359",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Burkina Faso",
    flag: "🇧🇫",
    code: "BF",
    dialCode: "226",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Burundi",
    flag: "🇧🇮",
    code: "BI",
    dialCode: "257",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Cambodia",
    flag: "🇰🇭",
    code: "KH",
    dialCode: "855",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Cameroon",
    flag: "🇨🇲",
    code: "CM",
    dialCode: "237",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Canada",
    flag: "🇨🇦",
    code: "CA",
    dialCode: "1",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Cape Verde",
    flag: "🇨🇻",
    code: "CV",
    dialCode: "238",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Cayman Islands",
    flag: "🇰🇾",
    code: "KY",
    dialCode: "345",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Central African Republic",
    flag: "🇨🇫",
    code: "CF",
    dialCode: "236",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Chad",
    flag: "🇹🇩",
    code: "TD",
    dialCode: "235",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Chile",
    flag: "🇨🇱",
    code: "CL",
    dialCode: "56",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "China",
    flag: "🇨🇳",
    code: "CN",
    dialCode: "86",
    minLength: 12,
    maxLength: 12,
  },
  {
    name: "Christmas Island",
    flag: "🇨🇽",
    code: "CX",
    dialCode: "61",
    minLength: 15,
    maxLength: 15,
  },
  {
    name: "Cocos (Keeling) Islands",
    flag: "🇨🇨",
    code: "CC",
    dialCode: "61",
    minLength: 15,
    maxLength: 15,
  },
  {
    name: "Colombia",
    flag: "🇨🇴",
    code: "CO",
    dialCode: "57",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Comoros",
    flag: "🇰🇲",
    code: "KM",
    dialCode: "269",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Congo",
    flag: "🇨🇬",
    code: "CG",
    dialCode: "242",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    flag: "🇨🇩",
    code: "CD",
    dialCode: "243",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Cook Islands",
    flag: "🇨🇰",
    code: "CK",
    dialCode: "682",
    minLength: 5,
    maxLength: 5,
  },
  {
    name: "Costa Rica",
    flag: "🇨🇷",
    code: "CR",
    dialCode: "506",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Côte d'Ivoire",
    flag: "🇨🇮",
    code: "CI",
    dialCode: "225",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Croatia",
    flag: "🇭🇷",
    code: "HR",
    dialCode: "385",
    minLength: 12,
    maxLength: 12,
  },
  {
    name: "Cuba",
    flag: "🇨🇺",
    code: "CU",
    dialCode: "53",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Cyprus",
    flag: "🇨🇾",
    code: "CY",
    dialCode: "357",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Czech Republic",
    flag: "🇨🇿",
    code: "CZ",
    dialCode: "420",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Denmark",
    flag: "🇩🇰",
    code: "DK",
    dialCode: "45",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Djibouti",
    flag: "🇩🇯",
    code: "DJ",
    dialCode: "253",
    minLength: 6,
    maxLength: 6,
  },
  {
    name: "Dominica",
    flag: "🇩🇲",
    code: "DM",
    dialCode: "1767",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Dominican Republic",
    flag: "🇩🇴",
    code: "DO",
    dialCode: "1849",
    minLength: 12,
    maxLength: 12,
  },
  {
    name: "Ecuador",
    flag: "🇪🇨",
    code: "EC",
    dialCode: "593",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Egypt",
    flag: "🇪🇬",
    code: "EG",
    dialCode: "20",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "El Salvador",
    flag: "🇸🇻",
    code: "SV",
    dialCode: "503",
    minLength: 11,
    maxLength: 11,
  },
  {
    name: "Equatorial Guinea",
    flag: "🇬🇶",
    code: "GQ",
    dialCode: "240",
    minLength: 6,
    maxLength: 6,
  },
  {
    name: "Eritrea",
    flag: "🇪🇷",
    code: "ER",
    dialCode: "291",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Estonia",
    flag: "🇪🇪",
    code: "EE",
    dialCode: "372",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Ethiopia",
    flag: "🇪🇹",
    code: "ET",
    dialCode: "251",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Falkland Islands (Malvinas)",
    flag: "🇫🇰",
    code: "FK",
    dialCode: "500",
    minLength: 5,
    maxLength: 5,
  },
  {
    name: "Faroe Islands",
    flag: "🇫🇴",
    code: "FO",
    dialCode: "298",
    minLength: 6,
    maxLength: 6,
  },
  {
    name: "Fiji",
    flag: "🇫🇯",
    code: "FJ",
    dialCode: "679",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Finland",
    flag: "🇫🇮",
    code: "FI",
    dialCode: "358",
    minLength: 12,
    maxLength: 12,
  },
  {
    name: "France",
    flag: "🇫🇷",
    code: "FR",
    dialCode: "33",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "French Guiana",
    flag: "🇬🇫",
    code: "GF",
    dialCode: "594",
    minLength: 15,
    maxLength: 15,
  },
  {
    name: "French Polynesia",
    flag: "🇵🇫",
    code: "PF",
    dialCode: "689",
    minLength: 6,
    maxLength: 6,
  },
  {
    name: "French Southern Territories",
    flag: "🇹🇫",
    code: "TF",
    dialCode: "262",
    minLength: 15,
    maxLength: 15,
  },
  {
    name: "Gabon",
    flag: "🇬🇦",
    code: "GA",
    dialCode: "241",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Gambia",
    flag: "🇬🇲",
    code: "GM",
    dialCode: "220",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Georgia",
    flag: "🇬🇪",
    code: "GE",
    dialCode: "995",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Germany",
    flag: "🇩🇪",
    code: "DE",
    dialCode: "49",
    minLength: 9,
    maxLength: 13,
  },
  {
    name: "Ghana",
    flag: "🇬🇭",
    code: "GH",
    dialCode: "233",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Gibraltar",
    flag: "🇬🇮",
    code: "GI",
    dialCode: "350",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Greece",
    flag: "🇬🇷",
    code: "GR",
    dialCode: "30",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Greenland",
    flag: "🇬🇱",
    code: "GL",
    dialCode: "299",
    minLength: 6,
    maxLength: 6,
  },
  {
    name: "Grenada",
    flag: "🇬🇩",
    code: "GD",
    dialCode: "1473",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Guadeloupe",
    flag: "🇬🇵",
    code: "GP",
    dialCode: "590",
    minLength: 15,
    maxLength: 15,
  },
  {
    name: "Guam",
    flag: "🇬🇺",
    code: "GU",
    dialCode: "1671",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Guatemala",
    flag: "🇬🇹",
    code: "GT",
    dialCode: "502",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Guernsey",
    flag: "🇬🇬",
    code: "GG",
    dialCode: "44",
    minLength: 6,
    maxLength: 6,
  },
  {
    name: "Guinea",
    flag: "🇬🇳",
    code: "GN",
    dialCode: "224",
    minLength: 8,
    maxLength: 9,
  },
  {
    name: "Guinea-Bissau",
    flag: "🇬🇼",
    code: "GW",
    dialCode: "245",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Guyana",
    flag: "🇬🇾",
    code: "GY",
    dialCode: "592",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Haiti",
    flag: "🇭🇹",
    code: "HT",
    dialCode: "509",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Heard Island and Mcdonald Islands",
    flag: "🇭🇲",
    code: "HM",
    dialCode: "672",
    minLength: 15,
    maxLength: 15,
  },
  {
    name: "Holy See (Vatican City State)",
    flag: "🇻🇦",
    code: "VA",
    dialCode: "379",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Honduras",
    flag: "🇭🇳",
    code: "HN",
    dialCode: "504",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Hong Kong",
    flag: "🇭🇰",
    code: "HK",
    dialCode: "852",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Hungary",
    flag: "🇭🇺",
    code: "HU",
    dialCode: "36",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Iceland",
    flag: "🇮🇸",
    code: "IS",
    dialCode: "354",
    minLength: 7,
    maxLength: 9,
  },
  {
    name: "India",
    flag: "🇮🇳",
    code: "IN",
    dialCode: "91",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Indonesia",
    flag: "🇮🇩",
    code: "ID",
    dialCode: "62",
    minLength: 13,
    maxLength: 13,
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    flag: "🇮🇷",
    code: "IR",
    dialCode: "98",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Iraq",
    flag: "🇮🇶",
    code: "IQ",
    dialCode: "964",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Ireland",
    flag: "🇮🇪",
    code: "IE",
    dialCode: "353",
    minLength: 7,
    maxLength: 9,
  },
  {
    name: "Isle of Man",
    flag: "🇮🇲",
    code: "IM",
    dialCode: "44",
    minLength: 6,
    maxLength: 6,
  },
  {
    name: "Israel",
    flag: "🇮🇱",
    code: "IL",
    dialCode: "972",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Italy",
    flag: "🇮🇹",
    code: "IT",
    dialCode: "39",
    minLength: 13,
    maxLength: 13,
  },
  {
    name: "Jamaica",
    flag: "🇯🇲",
    code: "JM",
    dialCode: "1876",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Japan",
    flag: "🇯🇵",
    code: "JP",
    dialCode: "81",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Jersey",
    flag: "🇯🇪",
    code: "JE",
    dialCode: "44",
    minLength: 6,
    maxLength: 6,
  },
  {
    name: "Jordan",
    flag: "🇯🇴",
    code: "JO",
    dialCode: "962",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Kazakhstan",
    flag: "🇰🇿",
    code: "KZ",
    dialCode: "7",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Kenya",
    flag: "🇰🇪",
    code: "KE",
    dialCode: "254",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Kiribati",
    flag: "🇰🇮",
    code: "KI",
    dialCode: "686",
    minLength: 5,
    maxLength: 5,
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    flag: "🇰🇵",
    code: "KP",
    dialCode: "850",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Korea, Republic of South Korea",
    flag: "🇰🇷",
    code: "KR",
    dialCode: "82",
    minLength: 11,
    maxLength: 11,
  },
  {
    name: "Kosovo",
    flag: "🇽🇰",
    code: "XK",
    dialCode: "383",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Kuwait",
    flag: "🇰🇼",
    code: "KW",
    dialCode: "965",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Kyrgyzstan",
    flag: "🇰🇬",
    code: "KG",
    dialCode: "996",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Laos",
    flag: "🇱🇦",
    code: "LA",
    dialCode: "856",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Latvia",
    flag: "🇱🇻",
    code: "LV",
    dialCode: "371",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Lebanon",
    flag: "🇱🇧",
    code: "LB",
    dialCode: "961",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Lesotho",
    flag: "🇱🇸",
    code: "LS",
    dialCode: "266",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Liberia",
    flag: "🇱🇷",
    code: "LR",
    dialCode: "231",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Libyan Arab Jamahiriya",
    flag: "🇱🇾",
    code: "LY",
    dialCode: "218",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Liechtenstein",
    flag: "🇱🇮",
    code: "LI",
    dialCode: "423",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Lithuania",
    flag: "🇱🇹",
    code: "LT",
    dialCode: "370",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Luxembourg",
    flag: "🇱🇺",
    code: "LU",
    dialCode: "352",
    minLength: 11,
    maxLength: 11,
  },
  {
    name: "Macao",
    flag: "🇲🇴",
    code: "MO",
    dialCode: "853",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Macedonia",
    flag: "🇲🇰",
    code: "MK",
    dialCode: "389",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Madagascar",
    flag: "🇲🇬",
    code: "MG",
    dialCode: "261",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Malawi",
    flag: "🇲🇼",
    code: "MW",
    dialCode: "265",
    minLength: 7,
    maxLength: 9,
  },
  {
    name: "Malaysia",
    flag: "🇲🇾",
    code: "MY",
    dialCode: "60",
    minLength: 11,
    maxLength: 11,
  },
  {
    name: "Maldives",
    flag: "🇲🇻",
    code: "MV",
    dialCode: "960",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Mali",
    flag: "🇲🇱",
    code: "ML",
    dialCode: "223",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Malta",
    flag: "🇲🇹",
    code: "MT",
    dialCode: "356",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Marshall Islands",
    flag: "🇲🇭",
    code: "MH",
    dialCode: "692",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Martinique",
    flag: "🇲🇶",
    code: "MQ",
    dialCode: "596",
    minLength: 15,
    maxLength: 15,
  },
  {
    name: "Mauritania",
    flag: "🇲🇷",
    code: "MR",
    dialCode: "222",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Mauritius",
    flag: "🇲🇺",
    code: "MU",
    dialCode: "230",
    minLength: 7,
    maxLength: 8,
  },
  {
    name: "Mayotte",
    flag: "🇾🇹",
    code: "YT",
    dialCode: "262",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Mexico",
    flag: "🇲🇽",
    code: "MX",
    dialCode: "52",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    flag: "🇫🇲",
    code: "FM",
    dialCode: "691",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Moldova",
    flag: "🇲🇩",
    code: "MD",
    dialCode: "373",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Monaco",
    flag: "🇲🇨",
    code: "MC",
    dialCode: "377",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Mongolia",
    flag: "🇲🇳",
    code: "MN",
    dialCode: "976",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Montenegro",
    flag: "🇲🇪",
    code: "ME",
    dialCode: "382",
    minLength: 12,
    maxLength: 12,
  },
  {
    name: "Montserrat",
    flag: "🇲🇸",
    code: "MS",
    dialCode: "1664",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Morocco",
    flag: "🇲🇦",
    code: "MA",
    dialCode: "212",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Mozambique",
    flag: "🇲🇿",
    code: "MZ",
    dialCode: "258",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Myanmar",
    flag: "🇲🇲",
    code: "MM",
    dialCode: "95",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Namibia",
    flag: "🇳🇦",
    code: "NA",
    dialCode: "264",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Nauru",
    flag: "🇳🇷",
    code: "NR",
    dialCode: "674",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Nepal",
    flag: "🇳🇵",
    code: "NP",
    dialCode: "977",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Netherlands",
    flag: "🇳🇱",
    code: "NL",
    dialCode: "31",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Netherlands Antilles",
    flag: "",
    code: "AN",
    dialCode: "599",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "New Caledonia",
    flag: "🇳🇨",
    code: "NC",
    dialCode: "687",
    minLength: 6,
    maxLength: 6,
  },
  {
    name: "New Zealand",
    flag: "🇳🇿",
    code: "NZ",
    dialCode: "64",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Nicaragua",
    flag: "🇳🇮",
    code: "NI",
    dialCode: "505",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Niger",
    flag: "🇳🇪",
    code: "NE",
    dialCode: "227",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Nigeria",
    flag: "🇳🇬",
    code: "NG",
    dialCode: "234",
    minLength: 10,
    maxLength: 11,
  },
  {
    name: "Niue",
    flag: "🇳🇺",
    code: "NU",
    dialCode: "683",
    minLength: 4,
    maxLength: 4,
  },
  {
    name: "Norfolk Island",
    flag: "🇳🇫",
    code: "NF",
    dialCode: "672",
    minLength: 15,
    maxLength: 15,
  },
  {
    name: "Northern Mariana Islands",
    flag: "🇲🇵",
    code: "MP",
    dialCode: "1670",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Norway",
    flag: "🇳🇴",
    code: "NO",
    dialCode: "47",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Oman",
    flag: "🇴🇲",
    code: "OM",
    dialCode: "968",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Pakistan",
    flag: "🇵🇰",
    code: "PK",
    dialCode: "92",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Palau",
    flag: "🇵🇼",
    code: "PW",
    dialCode: "680",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Palestinian Territory, Occupied",
    flag: "🇵🇸",
    code: "PS",
    dialCode: "970",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Panama",
    flag: "🇵🇦",
    code: "PA",
    dialCode: "507",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Papua New Guinea",
    flag: "🇵🇬",
    code: "PG",
    dialCode: "675",
    minLength: 11,
    maxLength: 11,
  },
  {
    name: "Paraguay",
    flag: "🇵🇾",
    code: "PY",
    dialCode: "595",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Peru",
    flag: "🇵🇪",
    code: "PE",
    dialCode: "51",
    minLength: 11,
    maxLength: 11,
  },
  {
    name: "Philippines",
    flag: "🇵🇭",
    code: "PH",
    dialCode: "63",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Pitcairn",
    flag: "🇵🇳",
    code: "PN",
    dialCode: "64",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Poland",
    flag: "🇵🇱",
    code: "PL",
    dialCode: "48",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Portugal",
    flag: "🇵🇹",
    code: "PT",
    dialCode: "351",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Puerto Rico",
    flag: "🇵🇷",
    code: "PR",
    dialCode: "1939",
    minLength: 15,
    maxLength: 15,
  },
  {
    name: "Qatar",
    flag: "🇶🇦",
    code: "QA",
    dialCode: "974",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Romania",
    flag: "🇷🇴",
    code: "RO",
    dialCode: "40",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Russia",
    flag: "🇷🇺",
    code: "RU",
    dialCode: "7",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Rwanda",
    flag: "🇷🇼",
    code: "RW",
    dialCode: "250",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Reunion",
    flag: "🇷🇪",
    code: "RE",
    dialCode: "262",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Saint Barthelemy",
    flag: "🇧🇱",
    code: "BL",
    dialCode: "590",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    flag: "🇸🇭",
    code: "SH",
    dialCode: "290",
    minLength: 4,
    maxLength: 4,
  },
  {
    name: "Saint Kitts and Nevis",
    flag: "🇰🇳",
    code: "KN",
    dialCode: "1869",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Saint Lucia",
    flag: "🇱🇨",
    code: "LC",
    dialCode: "1758",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Saint Martin",
    flag: "🇲🇫",
    code: "MF",
    dialCode: "590",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Saint Pierre and Miquelon",
    flag: "🇵🇲",
    code: "PM",
    dialCode: "508",
    minLength: 6,
    maxLength: 6,
  },
  {
    name: "Saint Vincent and the Grenadines",
    flag: "🇻🇨",
    code: "VC",
    dialCode: "1784",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Samoa",
    flag: "🇼🇸",
    code: "WS",
    dialCode: "685",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "San Marino",
    flag: "🇸🇲",
    code: "SM",
    dialCode: "378",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Sao Tome and Principe",
    flag: "🇸🇹",
    code: "ST",
    dialCode: "239",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Saudi Arabia",
    flag: "🇸🇦",
    code: "SA",
    dialCode: "966",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Senegal",
    flag: "🇸🇳",
    code: "SN",
    dialCode: "221",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Serbia",
    flag: "🇷🇸",
    code: "RS",
    dialCode: "381",
    minLength: 12,
    maxLength: 12,
  },
  {
    name: "Seychelles",
    flag: "🇸🇨",
    code: "SC",
    dialCode: "248",
    minLength: 6,
    maxLength: 6,
  },
  {
    name: "Sierra Leone",
    flag: "🇸🇱",
    code: "SL",
    dialCode: "232",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Singapore",
    flag: "🇸🇬",
    code: "SG",
    dialCode: "65",
    minLength: 12,
    maxLength: 12,
  },
  {
    name: "Slovakia",
    flag: "🇸🇰",
    code: "SK",
    dialCode: "421",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Slovenia",
    flag: "🇸🇮",
    code: "SI",
    dialCode: "386",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Solomon Islands",
    flag: "🇸🇧",
    code: "SB",
    dialCode: "677",
    minLength: 5,
    maxLength: 5,
  },
  {
    name: "Somalia",
    flag: "🇸🇴",
    code: "SO",
    dialCode: "252",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "South Africa",
    flag: "🇿🇦",
    code: "ZA",
    dialCode: "27",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "South Sudan",
    flag: "🇸🇸",
    code: "SS",
    dialCode: "211",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    flag: "🇬🇸",
    code: "GS",
    dialCode: "500",
    minLength: 15,
    maxLength: 15,
  },
  {
    name: "Spain",
    flag: "🇪🇸",
    code: "ES",
    dialCode: "34",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Sri Lanka",
    flag: "🇱🇰",
    code: "LK",
    dialCode: "94",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Sudan",
    flag: "🇸🇩",
    code: "SD",
    dialCode: "249",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Suriname",
    flag: "🇸🇷",
    code: "SR",
    dialCode: "597",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Svalbard and Jan Mayen",
    flag: "🇸🇯",
    code: "SJ",
    dialCode: "47",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Eswatini",
    flag: "🇸🇿",
    code: "SZ",
    dialCode: "268",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Sweden",
    flag: "🇸🇪",
    code: "SE",
    dialCode: "46",
    minLength: 13,
    maxLength: 13,
  },
  {
    name: "Switzerland",
    flag: "🇨🇭",
    code: "CH",
    dialCode: "41",
    minLength: 12,
    maxLength: 12,
  },
  {
    name: "Syrian Arab Republic",
    flag: "🇸🇾",
    code: "SY",
    dialCode: "963",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Taiwan",
    flag: "🇹🇼",
    code: "TW",
    dialCode: "886",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Tajikistan",
    flag: "🇹🇯",
    code: "TJ",
    dialCode: "992",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    flag: "🇹🇿",
    code: "TZ",
    dialCode: "255",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Thailand",
    flag: "🇹🇭",
    code: "TH",
    dialCode: "66",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Timor-Leste",
    flag: "🇹🇱",
    code: "TL",
    dialCode: "670",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Togo",
    flag: "🇹🇬",
    code: "TG",
    dialCode: "228",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Tokelau",
    flag: "🇹🇰",
    code: "TK",
    dialCode: "690",
    minLength: 4,
    maxLength: 4,
  },
  {
    name: "Tonga",
    flag: "🇹🇴",
    code: "TO",
    dialCode: "676",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Trinidad and Tobago",
    flag: "🇹🇹",
    code: "TT",
    dialCode: "1868",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Tunisia",
    flag: "🇹🇳",
    code: "TN",
    dialCode: "216",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Turkey",
    flag: "🇹🇷",
    code: "TR",
    dialCode: "90",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Turkmenistan",
    flag: "🇹🇲",
    code: "TM",
    dialCode: "993",
    minLength: 8,
    maxLength: 8,
  },
  {
    name: "Turks and Caicos Islands",
    flag: "🇹🇨",
    code: "TC",
    dialCode: "1649",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Tuvalu",
    flag: "🇹🇻",
    code: "TV",
    dialCode: "688",
    minLength: 6,
    maxLength: 6,
  },
  {
    name: "Uganda",
    flag: "🇺🇬",
    code: "UG",
    dialCode: "256",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Ukraine",
    flag: "🇺🇦",
    code: "UA",
    dialCode: "380",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "United Arab Emirates",
    flag: "🇦🇪",
    code: "AE",
    dialCode: "971",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "United Kingdom",
    flag: "🇬🇧",
    code: "GB",
    dialCode: "44",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "United States",
    flag: "🇺🇸",
    code: "US",
    dialCode: "1",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Uruguay",
    flag: "🇺🇾",
    code: "UY",
    dialCode: "598",
    minLength: 11,
    maxLength: 11,
  },
  {
    name: "Uzbekistan",
    flag: "🇺🇿",
    code: "UZ",
    dialCode: "998",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Vanuatu",
    flag: "🇻🇺",
    code: "VU",
    dialCode: "678",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    flag: "🇻🇪",
    code: "VE",
    dialCode: "58",
    minLength: 10,
    maxLength: 10,
  },
  {
    name: "Vietnam",
    flag: "🇻🇳",
    code: "VN",
    dialCode: "84",
    minLength: 11,
    maxLength: 11,
  },
  {
    name: "Virgin Islands, British",
    flag: "🇻🇬",
    code: "VG",
    dialCode: "1284",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Virgin Islands, U.S.",
    flag: "🇻🇮",
    code: "VI",
    dialCode: "1340",
    minLength: 7,
    maxLength: 7,
  },
  {
    name: "Wallis and Futuna",
    flag: "🇼🇫",
    code: "WF",
    dialCode: "681",
    minLength: 6,
    maxLength: 6,
  },
  {
    name: "Yemen",
    flag: "🇾🇪",
    code: "YE",
    dialCode: "967",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Zambia",
    flag: "🇿🇲",
    code: "ZM",
    dialCode: "260",
    minLength: 9,
    maxLength: 9,
  },
  {
    name: "Zimbabwe",
    flag: "🇿🇼",
    code: "ZW",
    dialCode: "263",
    minLength: 9,
    maxLength: 9,
  },
];

const countriesWithTimezones = {
  AD: ["Europe/Andorra"],
  AE: ["Asia/Dubai"],
  AF: ["Asia/Kabul"],
  AG: ["America/Puerto_Rico", "America/Antigua"],
  AI: ["America/Puerto_Rico", "America/Anguilla"],
  AL: ["Europe/Tirane"],
  AM: ["Asia/Yerevan"],
  AO: ["Africa/Lagos", "Africa/Luanda"],
  AQ: [
    "Antarctica/Casey",
    "Antarctica/Davis",
    "Antarctica/Mawson",
    "Antarctica/Palmer",
    "Antarctica/Rothera",
    "Antarctica/Troll",
    "Antarctica/Vostok",
    "Pacific/Auckland",
    "Pacific/Port_Moresby",
    "Asia/Riyadh",
    "Antarctica/McMurdo",
    "Antarctica/DumontDUrville",
    "Antarctica/Syowa",
  ],
  AR: [
    "America/Argentina/Buenos_Aires",
    "America/Argentina/Cordoba",
    "America/Argentina/Salta",
    "America/Argentina/Jujuy",
    "America/Argentina/Tucuman",
    "America/Argentina/Catamarca",
    "America/Argentina/La_Rioja",
    "America/Argentina/San_Juan",
    "America/Argentina/Mendoza",
    "America/Argentina/San_Luis",
    "America/Argentina/Rio_Gallegos",
    "America/Argentina/Ushuaia",
  ],
  AS: ["Pacific/Pago_Pago"],
  AT: ["Europe/Vienna"],
  AU: [
    "Australia/Lord_Howe",
    "Antarctica/Macquarie",
    "Australia/Hobart",
    "Australia/Melbourne",
    "Australia/Sydney",
    "Australia/Broken_Hill",
    "Australia/Brisbane",
    "Australia/Lindeman",
    "Australia/Adelaide",
    "Australia/Darwin",
    "Australia/Perth",
    "Australia/Eucla",
  ],
  AW: ["America/Puerto_Rico", "America/Aruba"],
  AX: ["Europe/Helsinki", "Europe/Mariehamn"],
  AZ: ["Asia/Baku"],
  BA: ["Europe/Belgrade", "Europe/Sarajevo"],
  BB: ["America/Barbados"],
  BD: ["Asia/Dhaka"],
  BE: ["Europe/Brussels"],
  BF: ["Africa/Abidjan", "Africa/Ouagadougou"],
  BG: ["Europe/Sofia"],
  BH: ["Asia/Qatar", "Asia/Bahrain"],
  BI: ["Africa/Maputo", "Africa/Bujumbura"],
  BJ: ["Africa/Lagos", "Africa/Porto-Novo"],
  BL: ["America/Puerto_Rico", "America/St_Barthelemy"],
  BM: ["Atlantic/Bermuda"],
  BN: ["Asia/Kuching", "Asia/Brunei"],
  BO: ["America/La_Paz"],
  BQ: ["America/Puerto_Rico", "America/Kralendijk"],
  BR: [
    "America/Noronha",
    "America/Belem",
    "America/Fortaleza",
    "America/Recife",
    "America/Araguaina",
    "America/Maceio",
    "America/Bahia",
    "America/Sao_Paulo",
    "America/Campo_Grande",
    "America/Cuiaba",
    "America/Santarem",
    "America/Porto_Velho",
    "America/Boa_Vista",
    "America/Manaus",
    "America/Eirunepe",
    "America/Rio_Branco",
  ],
  BS: ["America/Toronto", "America/Nassau"],
  BT: ["Asia/Thimphu"],
  BW: ["Africa/Maputo", "Africa/Gaborone"],
  BY: ["Europe/Minsk"],
  BZ: ["America/Belize"],
  CA: [
    "America/St_Johns",
    "America/Halifax",
    "America/Glace_Bay",
    "America/Moncton",
    "America/Goose_Bay",
    "America/Toronto",
    "America/Iqaluit",
    "America/Winnipeg",
    "America/Resolute",
    "America/Rankin_Inlet",
    "America/Regina",
    "America/Swift_Current",
    "America/Edmonton",
    "America/Cambridge_Bay",
    "America/Inuvik",
    "America/Dawson_Creek",
    "America/Fort_Nelson",
    "America/Whitehorse",
    "America/Dawson",
    "America/Vancouver",
    "America/Panama",
    "America/Puerto_Rico",
    "America/Phoenix",
    "America/Blanc-Sablon",
    "America/Atikokan",
    "America/Creston",
  ],
  CC: ["Asia/Yangon", "Indian/Cocos"],
  CD: ["Africa/Maputo", "Africa/Lagos", "Africa/Kinshasa", "Africa/Lubumbashi"],
  CF: ["Africa/Lagos", "Africa/Bangui"],
  CG: ["Africa/Lagos", "Africa/Brazzaville"],
  CH: ["Europe/Zurich"],
  CI: ["Africa/Abidjan"],
  CK: ["Pacific/Rarotonga"],
  CL: ["America/Santiago", "America/Punta_Arenas", "Pacific/Easter"],
  CM: ["Africa/Lagos", "Africa/Douala"],
  CN: ["Asia/Shanghai", "Asia/Urumqi"],
  CO: ["America/Bogota"],
  CR: ["America/Costa_Rica"],
  CU: ["America/Havana"],
  CV: ["Atlantic/Cape_Verde"],
  CW: ["America/Puerto_Rico", "America/Curacao"],
  CX: ["Asia/Bangkok", "Indian/Christmas"],
  CY: ["Asia/Nicosia", "Asia/Famagusta"],
  CZ: ["Europe/Prague"],
  DE: ["Europe/Zurich", "Europe/Berlin", "Europe/Busingen"],
  DJ: ["Africa/Nairobi", "Africa/Djibouti"],
  DK: ["Europe/Berlin", "Europe/Copenhagen"],
  DM: ["America/Puerto_Rico", "America/Dominica"],
  DO: ["America/Santo_Domingo"],
  DZ: ["Africa/Algiers"],
  EC: ["America/Guayaquil", "Pacific/Galapagos"],
  EE: ["Europe/Tallinn"],
  EG: ["Africa/Cairo"],
  EH: ["Africa/El_Aaiun"],
  ER: ["Africa/Nairobi", "Africa/Asmara"],
  ES: ["Europe/Madrid", "Africa/Ceuta", "Atlantic/Canary"],
  ET: ["Africa/Nairobi", "Africa/Addis_Ababa"],
  FI: ["Europe/Helsinki"],
  FJ: ["Pacific/Fiji"],
  FK: ["Atlantic/Stanley"],
  FM: [
    "Pacific/Kosrae",
    "Pacific/Port_Moresby",
    "Pacific/Guadalcanal",
    "Pacific/Chuuk",
    "Pacific/Pohnpei",
  ],
  FO: ["Atlantic/Faroe"],
  FR: ["Europe/Paris"],
  GA: ["Africa/Lagos", "Africa/Libreville"],
  GB: ["Europe/London"],
  GD: ["America/Puerto_Rico", "America/Grenada"],
  GE: ["Asia/Tbilisi"],
  GF: ["America/Cayenne"],
  GG: ["Europe/London", "Europe/Guernsey"],
  GH: ["Africa/Abidjan", "Africa/Accra"],
  GI: ["Europe/Gibraltar"],
  GL: [
    "America/Nuuk",
    "America/Danmarkshavn",
    "America/Scoresbysund",
    "America/Thule",
  ],
  GM: ["Africa/Abidjan", "Africa/Banjul"],
  GN: ["Africa/Abidjan", "Africa/Conakry"],
  GP: ["America/Puerto_Rico", "America/Guadeloupe"],
  GQ: ["Africa/Lagos", "Africa/Malabo"],
  GR: ["Europe/Athens"],
  GS: ["Atlantic/South_Georgia"],
  GT: ["America/Guatemala"],
  GU: ["Pacific/Guam"],
  GW: ["Africa/Bissau"],
  GY: ["America/Guyana"],
  HK: ["Asia/Hong_Kong"],
  HN: ["America/Tegucigalpa"],
  HR: ["Europe/Belgrade", "Europe/Zagreb"],
  HT: ["America/Port-au-Prince"],
  HU: ["Europe/Budapest"],
  ID: ["Asia/Jakarta", "Asia/Pontianak", "Asia/Makassar", "Asia/Jayapura"],
  IE: ["Europe/Dublin"],
  IL: ["Asia/Jerusalem"],
  IM: ["Europe/London", "Europe/Isle_of_Man"],
  IN: ["Asia/Kolkata"],
  IO: ["Indian/Chagos"],
  IQ: ["Asia/Baghdad"],
  IR: ["Asia/Tehran"],
  IS: ["Africa/Abidjan", "Atlantic/Reykjavik"],
  IT: ["Europe/Rome"],
  JE: ["Europe/London", "Europe/Jersey"],
  JM: ["America/Jamaica"],
  JO: ["Asia/Amman"],
  JP: ["Asia/Tokyo"],
  KE: ["Africa/Nairobi"],
  KG: ["Asia/Bishkek"],
  KH: ["Asia/Bangkok", "Asia/Phnom_Penh"],
  KI: ["Pacific/Tarawa", "Pacific/Kanton", "Pacific/Kiritimati"],
  KM: ["Africa/Nairobi", "Indian/Comoro"],
  KN: ["America/Puerto_Rico", "America/St_Kitts"],
  KP: ["Asia/Pyongyang"],
  KR: ["Asia/Seoul"],
  KW: ["Asia/Riyadh", "Asia/Kuwait"],
  KY: ["America/Panama", "America/Cayman"],
  KZ: [
    "Asia/Almaty",
    "Asia/Qyzylorda",
    "Asia/Qostanay",
    "Asia/Aqtobe",
    "Asia/Aqtau",
    "Asia/Atyrau",
    "Asia/Oral",
  ],
  LA: ["Asia/Bangkok", "Asia/Vientiane"],
  LB: ["Asia/Beirut"],
  LC: ["America/Puerto_Rico", "America/St_Lucia"],
  LI: ["Europe/Zurich", "Europe/Vaduz"],
  LK: ["Asia/Colombo"],
  LR: ["Africa/Monrovia"],
  LS: ["Africa/Johannesburg", "Africa/Maseru"],
  LT: ["Europe/Vilnius"],
  LU: ["Europe/Brussels", "Europe/Luxembourg"],
  LV: ["Europe/Riga"],
  LY: ["Africa/Tripoli"],
  MA: ["Africa/Casablanca"],
  MC: ["Europe/Paris", "Europe/Monaco"],
  MD: ["Europe/Chisinau"],
  ME: ["Europe/Belgrade", "Europe/Podgorica"],
  MF: ["America/Puerto_Rico", "America/Marigot"],
  MG: ["Africa/Nairobi", "Indian/Antananarivo"],
  MH: ["Pacific/Tarawa", "Pacific/Kwajalein", "Pacific/Majuro"],
  MK: ["Europe/Belgrade", "Europe/Skopje"],
  ML: ["Africa/Abidjan", "Africa/Bamako"],
  MM: ["Asia/Yangon"],
  MN: ["Asia/Ulaanbaatar", "Asia/Hovd", "Asia/Choibalsan"],
  MO: ["Asia/Macau"],
  MP: ["Pacific/Guam", "Pacific/Saipan"],
  MQ: ["America/Martinique"],
  MR: ["Africa/Abidjan", "Africa/Nouakchott"],
  MS: ["America/Puerto_Rico", "America/Montserrat"],
  MT: ["Europe/Malta"],
  MU: ["Indian/Mauritius"],
  MV: ["Indian/Maldives"],
  MW: ["Africa/Maputo", "Africa/Blantyre"],
  MX: [
    "America/Mexico_City",
    "America/Cancun",
    "America/Merida",
    "America/Monterrey",
    "America/Matamoros",
    "America/Chihuahua",
    "America/Ciudad_Juarez",
    "America/Ojinaga",
    "America/Mazatlan",
    "America/Bahia_Banderas",
    "America/Hermosillo",
    "America/Tijuana",
  ],
  MY: ["Asia/Kuching", "Asia/Singapore", "Asia/Kuala_Lumpur"],
  MZ: ["Africa/Maputo"],
  NA: ["Africa/Windhoek"],
  NC: ["Pacific/Noumea"],
  NE: ["Africa/Lagos", "Africa/Niamey"],
  NF: ["Pacific/Norfolk"],
  NG: ["Africa/Lagos"],
  NI: ["America/Managua"],
  NL: ["Europe/Brussels", "Europe/Amsterdam"],
  NO: ["Europe/Berlin", "Europe/Oslo"],
  NP: ["Asia/Kathmandu"],
  NR: ["Pacific/Nauru"],
  NU: ["Pacific/Niue"],
  NZ: ["Pacific/Auckland", "Pacific/Chatham"],
  OM: ["Asia/Dubai", "Asia/Muscat"],
  PA: ["America/Panama"],
  PE: ["America/Lima"],
  PF: ["Pacific/Tahiti", "Pacific/Marquesas", "Pacific/Gambier"],
  PG: ["Pacific/Port_Moresby", "Pacific/Bougainville"],
  PH: ["Asia/Manila"],
  PK: ["Asia/Karachi"],
  PL: ["Europe/Warsaw"],
  PM: ["America/Miquelon"],
  PN: ["Pacific/Pitcairn"],
  PR: ["America/Puerto_Rico"],
  PS: ["Asia/Gaza", "Asia/Hebron"],
  PT: ["Europe/Lisbon", "Atlantic/Madeira", "Atlantic/Azores"],
  PW: ["Pacific/Palau"],
  PY: ["America/Asuncion"],
  QA: ["Asia/Qatar"],
  RE: ["Asia/Dubai", "Indian/Reunion"],
  RO: ["Europe/Bucharest"],
  RS: ["Europe/Belgrade"],
  RU: [
    "Europe/Kaliningrad",
    "Europe/Moscow",
    "Europe/Simferopol",
    "Europe/Kirov",
    "Europe/Volgograd",
    "Europe/Astrakhan",
    "Europe/Saratov",
    "Europe/Ulyanovsk",
    "Europe/Samara",
    "Asia/Yekaterinburg",
    "Asia/Omsk",
    "Asia/Novosibirsk",
    "Asia/Barnaul",
    "Asia/Tomsk",
    "Asia/Novokuznetsk",
    "Asia/Krasnoyarsk",
    "Asia/Irkutsk",
    "Asia/Chita",
    "Asia/Yakutsk",
    "Asia/Khandyga",
    "Asia/Vladivostok",
    "Asia/Ust-Nera",
    "Asia/Magadan",
    "Asia/Sakhalin",
    "Asia/Srednekolymsk",
    "Asia/Kamchatka",
    "Asia/Anadyr",
  ],
  RW: ["Africa/Maputo", "Africa/Kigali"],
  SA: ["Asia/Riyadh"],
  SB: ["Pacific/Guadalcanal"],
  SC: ["Asia/Dubai", "Indian/Mahe"],
  SD: ["Africa/Khartoum"],
  SE: ["Europe/Berlin", "Europe/Stockholm"],
  SG: ["Asia/Singapore"],
  SH: ["Africa/Abidjan", "Atlantic/St_Helena"],
  SI: ["Europe/Belgrade", "Europe/Ljubljana"],
  SJ: ["Europe/Berlin", "Arctic/Longyearbyen"],
  SK: ["Europe/Prague", "Europe/Bratislava"],
  SL: ["Africa/Abidjan", "Africa/Freetown"],
  SM: ["Europe/Rome", "Europe/San_Marino"],
  SN: ["Africa/Abidjan", "Africa/Dakar"],
  SO: ["Africa/Nairobi", "Africa/Mogadishu"],
  SR: ["America/Paramaribo"],
  SS: ["Africa/Juba"],
  ST: ["Africa/Sao_Tome"],
  SV: ["America/El_Salvador"],
  SX: ["America/Puerto_Rico", "America/Lower_Princes"],
  SY: ["Asia/Damascus"],
  SZ: ["Africa/Johannesburg", "Africa/Mbabane"],
  TC: ["America/Grand_Turk"],
  TD: ["Africa/Ndjamena"],
  TF: ["Asia/Dubai", "Indian/Maldives", "Indian/Kerguelen"],
  TG: ["Africa/Abidjan", "Africa/Lome"],
  TH: ["Asia/Bangkok"],
  TJ: ["Asia/Dushanbe"],
  TK: ["Pacific/Fakaofo"],
  TL: ["Asia/Dili"],
  TM: ["Asia/Ashgabat"],
  TN: ["Africa/Tunis"],
  TO: ["Pacific/Tongatapu"],
  TR: ["Europe/Istanbul"],
  TT: ["America/Puerto_Rico", "America/Port_of_Spain"],
  TV: ["Pacific/Tarawa", "Pacific/Funafuti"],
  TW: ["Asia/Taipei"],
  TZ: ["Africa/Nairobi", "Africa/Dar_es_Salaam"],
  UA: ["Europe/Simferopol", "Europe/Kyiv"],
  UG: ["Africa/Nairobi", "Africa/Kampala"],
  UM: ["Pacific/Pago_Pago", "Pacific/Tarawa", "Pacific/Midway", "Pacific/Wake"],
  US: [
    "America/New_York",
    "America/Detroit",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Indiana/Indianapolis",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Vevay",
    "America/Chicago",
    "America/Indiana/Tell_City",
    "America/Indiana/Knox",
    "America/Menominee",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/North_Dakota/Beulah",
    "America/Denver",
    "America/Boise",
    "America/Phoenix",
    "America/Los_Angeles",
    "America/Anchorage",
    "America/Juneau",
    "America/Sitka",
    "America/Metlakatla",
    "America/Yakutat",
    "America/Nome",
    "America/Adak",
    "Pacific/Honolulu",
  ],
  UY: ["America/Montevideo"],
  UZ: ["Asia/Samarkand", "Asia/Tashkent"],
  VA: ["Europe/Rome", "Europe/Vatican"],
  VC: ["America/Puerto_Rico", "America/St_Vincent"],
  VE: ["America/Caracas"],
  VG: ["America/Puerto_Rico", "America/Tortola"],
  VI: ["America/Puerto_Rico", "America/St_Thomas"],
  VN: ["Asia/Bangkok", "Asia/Ho_Chi_Minh"],
  VU: ["Pacific/Efate"],
  WF: ["Pacific/Tarawa", "Pacific/Wallis"],
  WS: ["Pacific/Apia"],
  YE: ["Asia/Riyadh", "Asia/Aden"],
  YT: ["Africa/Nairobi", "Indian/Mayotte"],
  ZA: ["Africa/Johannesburg"],
  ZM: ["Africa/Maputo", "Africa/Lusaka"],
  ZW: ["Africa/Maputo", "Africa/Harare"],
};

const countriesWithTimezonesAbbr = {
  AD: ["CET"], // Andorra
  AE: ["GST"], // United Arab Emirates
  AF: ["AFT"], // Afghanistan
  AG: ["AST"], // Antigua and Barbuda
  AI: ["AST"], // Anguilla
  AL: ["CET"], // Albania
  AM: ["AMT"], // Armenia
  AO: ["WAT"], // Angola
  AQ: ["NZDT", "NZST", "SAST"], // Antarctica
  AR: ["ART"], // Argentina
  AS: ["SST"], // American Samoa
  AT: ["CET"], // Austria
  AU: ["ACDT", "ACST", "AWST"], // Australia
  AW: ["AST"], // Aruba
  AX: ["EET"], // Åland Islands
  AZ: ["AZT"], // Azerbaijan
  BA: ["CET"], // Bosnia and Herzegovina
  BB: ["AST"], // Barbados
  BD: ["BST"], // Bangladesh
  BE: ["CET"], // Belgium
  BF: ["GMT"], // Burkina Faso
  BG: ["EET"], // Bulgaria
  BH: ["AST"], // Bahrain
  BI: ["CAT"], // Burundi
  BJ: ["WAT"], // Benin
  BL: ["AST"], // Saint Barthélemy
  BM: ["AST"], // Bermuda
  BN: ["BNT"], // Brunei
  BO: ["BOT"], // Bolivia
  BQ: ["AST"], // Bonaire, Sint Eustatius and Saba
  BR: ["BRT"], // Brazil
  BS: ["EST"], // Bahamas
  BT: ["BTT"], // Bhutan
  BW: ["CAT"], // Botswana
  BY: ["MSK"], // Belarus
  BZ: ["CST"], // Belize
  CA: ["PST", "MST", "CST", "EST", "AST", "NST"], // Canada
  CC: ["CXT"], // Cocos (Keeling) Islands
  CD: ["WAT", "CAT"], // Democratic Republic of the Congo
  CF: ["WAT"], // Central African Republic
  CG: ["WAT"], // Republic of the Congo
  CH: ["CET"], // Switzerland
  CI: ["GMT"], // Ivory Coast
  CK: ["CKT"], // Cook Islands
  CL: ["CLT"], // Chile
  CM: ["WAT"], // Cameroon
  CN: ["CST"], // China
  CO: ["COT"], // Colombia
  CR: ["CST"], // Costa Rica
  CU: ["CST"], // Cuba
  CV: ["CVT"], // Cape Verde
  CW: ["AST"], // Curaçao
  CX: ["CXT"], // Christmas Island
  CY: ["EET"], // Cyprus
  CZ: ["CET"], // Czech Republic
  DE: ["CET"], // Germany
  DJ: ["EAT"], // Djibouti
  DK: ["CET"], // Denmark
  DM: ["AST"], // Dominica
  DO: ["AST"], // Dominican Republic
  DZ: ["CET"], // Algeria
  EC: ["ECT"], // Ecuador
  EE: ["EET"], // Estonia
  EG: ["EET"], // Egypt
  EH: ["WET"], // Western Sahara
  ER: ["EAT"], // Eritrea
  ES: ["CET"], // Spain
  ET: ["EAT"], // Ethiopia
  FI: ["EET"], // Finland
  FJ: ["FJT"], // Fiji
  FK: ["FKT"], // Falkland Islands
  FM: ["CHST"], // Micronesia
  FO: ["WET"], // Faroe Islands
  FR: ["CET"], // France
  GA: ["WAT"], // Gabon
  GB: ["GMT"], // United Kingdom
  GD: ["AST"], // Grenada
  GE: ["GET"], // Georgia
  GF: ["GFT"], // French Guiana
  GG: ["GMT"], // Guernsey
  GH: ["GMT"], // Ghana
  GI: ["CET"], // Gibraltar
  GL: ["WGT"], // Greenland
  GM: ["GMT"], // Gambia
  GN: ["GMT"], // Guinea
  GP: ["AST"], // Guadeloupe
  GQ: ["WAT"], // Equatorial Guinea
  GR: ["EET"], // Greece
  GS: ["GST"], // South Georgia and the South Sandwich Islands
  GT: ["CST"], // Guatemala
  GU: ["ChST"], // Guam
  GW: ["GMT"], // Guinea-Bissau
  GY: ["GYT"], // Guyana
  HK: ["HKT"], // Hong Kong
  HN: ["CST"], // Honduras
  HR: ["CET"], // Croatia
  HT: ["EST"], // Haiti
  HU: ["CET"], // Hungary
  ID: ["WIB", "WITA", "WIT"], // Indonesia
  IE: ["GMT"], // Ireland
  IL: ["IST"], // Israel
  IM: ["GMT"], // Isle of Man
  IN: ["IST"], // India
  IO: ["IOT"], // British Indian Ocean Territory
  IQ: ["AST"], // Iraq
  IR: ["IRST", "IRDT"], // Iran
  IS: ["GMT"], // Iceland
  IT: ["CET"], // Italy
  JE: ["GMT"], // Jersey
  JM: ["EST"], // Jamaica
  JO: ["EET"], // Jordan
  JP: ["JST"], // Japan
  KE: ["EAT"], // Kenya
  KG: ["KGT"], // Kyrgyzstan
  KH: ["ICT"], // Cambodia
  KI: ["GILT", "LINT"], // Kiribati
  KM: ["EAT"], // Comoros
  KN: ["AST"], // Saint Kitts and Nevis
  KP: ["KST"], // North Korea
  KR: ["KST"], // South Korea
  KW: ["AST"], // Kuwait
  KY: ["EST"], // Cayman Islands
  KZ: ["ALMT", "QYZT", "AQTT", "AQTT", "AKTT", "AQTT", "ORAT"], // Kazakhstan
  LA: ["ICT"], // Laos
  LB: ["EET"], // Lebanon
  LC: ["AST"], // Saint Lucia
  LI: ["CET"], // Liechtenstein
  LK: ["IST"], // Sri Lanka
  LR: ["GMT"], // Liberia
  LS: ["SAST"], // Lesotho
  LT: ["EET"], // Lithuania
  LU: ["CET"], // Luxembourg
  LV: ["EET"], // Latvia
  LY: ["EET"], // Libya
  MA: ["WET"], // Morocco
  MC: ["CET"], // Monaco
  MD: ["EET"], // Moldova
  ME: ["CET"], // Montenegro
  MF: ["AST"], // Saint Martin (French part)
  MG: ["EAT"], // Madagascar
  MH: ["MHT"], // Marshall Islands
  MK: ["CET"], // North Macedonia
  ML: ["GMT"], // Mali
  MM: ["MMT"], // Myanmar
  MN: ["ULAT", "HOVT", "HOVST"], // Mongolia
  MO: ["CST"], // Macao
  MP: ["ChST"], // Northern Mariana Islands
  MQ: ["AST"], // Martinique
  MR: ["GMT"], // Mauritania
  MS: ["AST"], // Montserrat
  MT: ["CET"], // Malta
  MU: ["MUT"], // Mauritius
  MV: ["MVT"], // Maldives
  MW: ["CAT"], // Malawi
  MX: [
    "CST",
    "CST",
    "MDT",
    "CST",
    "CST",
    "MDT",
    "CST",
    "CST",
    "MDT",
    "PDT",
    "MST",
    "PST",
  ], // Mexico
  MY: ["MYT"], // Malaysia
  MZ: ["CAT"], // Mozambique
  NA: ["CAT"], // Namibia
  NC: ["NCT"], // New Caledonia
  NE: ["WAT"], // Niger
  NF: ["NFT"], // Norfolk Island
  NG: ["WAT"], // Nigeria
  NI: ["CST"], // Nicaragua
  NL: ["CET"], // Netherlands
  NO: ["CET", "CEST"], // Norway
  NP: ["NPT"], // Nepal
  NR: ["NRT"], // Nauru
  NU: ["NUT"], // Niue
  NZ: ["NZST", "CHADT"], // New Zealand
  OM: ["GST"], // Oman
  PA: ["EST"], // Panama
  PE: ["PET"], // Peru
  PF: ["TAHT", "MART", "GAMT"], // French Polynesia
  PG: ["PGT"], // Papua New Guinea
  PH: ["PST", "PHT"], // Philippines
  PK: ["PKT"], // Pakistan
  PL: ["CET"], // Poland
  PM: ["PMST"], // Saint Pierre and Miquelon
  PN: ["PST"], // Pitcairn
  PR: ["AST"], // Puerto Rico
  PS: ["EET"], // Palestine
  PT: ["WET", "WEST"], // Portugal
  PW: ["CHUT"], // Palau
  PY: ["PYT"], // Paraguay
  QA: ["AST"], // Qatar
  RE: ["RET"], // Réunion
  RO: ["EET"], // Romania
  RS: ["CET"], // Serbia
  RU: [
    "FET",
    "MSK",
    "MSD",
    "MSK",
    "MSD",
    "MSK",
    "SAMT",
    "SAMT",
    "YEKT",
    "OMST",
    "OMSST",
    "KRAT",
    "KRAT",
    "IRKT",
    "IRKT",
    "YAKT",
    "YAKT",
    "VLAT",
    "VLAT",
    "VLAST",
    "MAGT",
    "MAGT",
    "MAGST",
    "PETT",
    "PETT",
    "ANAT",
    "ANAT",
    "ANAST",
    "AEST",
    "AEST",
    "AEDT",
  ], // Russia
  RW: ["CAT"], // Rwanda
  SA: ["AST"], // Saudi Arabia
  SB: ["SBT"], // Solomon Islands
  SC: ["SCT"], // Seychelles
  SD: ["CAT"], // Sudan
  SE: ["CET", "CEST"], // Sweden
  SG: ["SGT"], // Singapore
  SH: ["GMT"], // Saint Helena, Ascension and Tristan da Cunha
  SI: ["CET", "CEST"], // Slovenia
  SJ: ["CET", "CEST"], // Svalbard and Jan Mayen
  SK: ["CET", "CEST"], // Slovakia
  SL: ["GMT"], // Sierra Leone
  SM: ["CET"], // San Marino
  SN: ["GMT"], // Senegal
  SO: ["EAT"], // Somalia
  SR: ["SRT"], // Suriname
  SS: ["CAT"], // South Sudan
  ST: ["GMT"], // São Tomé and Príncipe
  SV: ["CST"], // El Salvador
  SX: ["AST"], // Sint Maarten (Dutch part)
  SY: ["EET"], // Syria
  SZ: ["CAT"], // Eswatini
  TC: ["EST"], // Turks and Caicos Islands
  TD: ["WAT"], // Chad
  TF: ["GST", "MVT", "TFT"], // French Southern Territories
  TG: ["GMT"], // Togo
  TH: ["ICT"], // Thailand
  TJ: ["TJT"], // Tajikistan
  TK: ["TKT"], // Tokelau
  TL: ["TLT"], // Timor-Leste
  TM: ["TMT"], // Turkmenistan
  TN: ["CET"], // Tunisia
  TO: ["TOT"], // Tonga
  TR: ["TRT"], // Turkey
  TT: ["AST"], // Trinidad and Tobago
  TV: ["TVT"], // Tuvalu
  TW: ["CST"], // Taiwan
  TZ: ["EAT"], // Tanzania
  UA: ["EET", "CEST"], // Ukraine
  UG: ["EAT"], // Uganda
  UM: [], // United States Minor Outlying Islands (No specific timezone)
  US: [
    "HST",
    "AKST",
    "AKDT",
    "PST",
    "PDT",
    "MST",
    "MDT",
    "CST",
    "CDT",
    "EST",
    "EDT",
    "AST",
    "CHST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
    "SST",
  ], // United States
  UY: ["UYT"], // Uruguay
  UZ: ["UZT"], // Uzbekistan
  VA: ["CET"], // Vatican City
  VC: ["AST"], // Saint Vincent and the Grenadines
  VE: ["VET"], // Venezuela
  VG: ["AST"], // British Virgin Islands
  VI: ["AST"], // U.S. Virgin Islands
  VN: ["ICT"], // Vietnam
  VU: ["VUT"], // Vanuatu
  WF: ["WFT"], // Wallis and Futuna
  WS: ["WST"], // Samoa
  YE: ["AST"], // Yemen
  YT: ["EAT"], // Mayotte
  ZA: ["SAST"], // South Africa
  ZM: ["CAT"], // Zambia
  ZW: ["CAT"], // Zimbabwe
};

const countriesUtility = {
  countries,
  getCountryDialingCode,
  getCountryName,
  getCountryTimezones,
  getCountryISOCode,
  getCountryTimezonesAbbr,
};

export default countriesUtility;
