import React from "react";
import { UserCountryDataProvider } from "./user-context";
import { AuthProvider } from "./auth-context";
import { AuthPopupProvider } from "./auth-popups-context";
import { ChannelProvider } from "./channel-context";
import { VideoProvider } from "./video-context";
import { LanguageProvider } from "./language-context";
// Main context provider component
const MainContextProvider = ({ children }) => {
  return (
    <LanguageProvider>
      <UserCountryDataProvider>
        <ChannelProvider>
          <VideoProvider>
            <AuthProvider>
              <AuthPopupProvider>{children}</AuthPopupProvider>
            </AuthProvider>
          </VideoProvider>
        </ChannelProvider>
      </UserCountryDataProvider>
    </LanguageProvider>
  );
};

export default MainContextProvider;
