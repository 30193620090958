import React, { createContext, useContext, useState } from "react";
import { getUserPreferredLanguage } from "../helper/local-storage/local-storage-helper";

// Create the context
export const LanguageContext = createContext();

// Create a context provider component
export const LanguageProvider = ({ children }) => {
  const [userLanguage, setUserLanguage] = useState(getUserPreferredLanguage());

  const contextValues = {
    userLanguage, setUserLanguage  };

  return (
    <LanguageContext.Provider value={contextValues}>
      {children}
    </LanguageContext.Provider>
  );
};

// Create a custom hook for using the context
export const useLanguage = () => {
  const context = useContext(LanguageContext);
  return context;
};
