import React from "react";
import { useTranslation } from "react-i18next";


const Loading = () => {

  const { t } = useTranslation();

  return (
    <div
      id="loading-spinner"
      className="h-[52vh] lg:h-[67vh] flex items-center justify-center"
      data-testid="loading-spinner"
    >
      <div
        id="loading-text"
        className="flex items-center justify-center h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          {t("loading.loading_text")}
        </span>
      </div>
    </div>
  );
};

export default Loading;
