import moment from "moment";
import { DATE_FORMAT } from "../global/constants/app-constants";
import dateUtility from "../utils/date-utility";
import countriesUtility from "../utils/countries-utility";

/**
 * @desc Check if given value is string
 * @param {*} value // Accepts string
 */
export function isString(value) {
  let myRegEx = /^[a-zA-Z\s]*$/;
  let isValid = myRegEx.test(value);
  return isValid;
}

/**
 * @desc Checks for valid email
 * @param {*} value // Accepts string
 */
export function isEmail(value) {
  let myRegEx =
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let isValid = myRegEx.test(value);
  return isValid;
}

/**
 * @desc Checks for Empty string
 * @param {*} value // Accepts string, object
 */
export function isEmpty(value) {
  if (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  ) {
    return true;
  } else {
    return false;
  }
}

/**
 * @desc: Check valid date
 */
export function isValidDate(d) {
  return d instanceof Date;
}

/**
 * @desc: Check date is in format of YYYY-MM-DD
 * @format : YYYY-MM-DD
 */
export function isValidDateFormat(d) {
  // let myRegEx = /^[0-9]{4}-(((0[13578]|(10|12))-(0[1-9]|[1-2][0-9]|3[0-1]))|(02-(0[1-9]|[1-2][0-9]))|((0[469]|11)-(0[1-9]|[1-2][0-9]|30)))$/;
  let myRegEx =
    /\d{4}-[01]{1}\d{1}-[0-3]{1}\d{1}T[0-2]{1}\d{1}:[0-6]{1}\d{1}/gm;
  let isValid = myRegEx.test(d);
  return isValid;
}

/**
 * @desc: Check valid password
 * It contains at least 8 characters and at most 20 characters.
 * It contains at least one digit.
 * It contains at least one upper case alphabet.
 * It contains at least one lower case alphabet.
 * It contains at least one special character which includes !@#$%&*()-+=^.
 * It doesn’t contain any white space.
 */
// export function isValidPassword(password = null) {
//   let myRegEx =
//     /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=\\S+$).{8, 20}$/;
//   let isValid = myRegEx.test(password);
//   return isValid;
// }

export function isValidPassword(password = null) {
  // Adjusted regex pattern
  let myRegEx = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*()\-+=^])(?=\S+$).{8,20}$/;
  let isValid = myRegEx.test(password);
  return isValid;
}

/**
 * @desc it return unique GUID string
 */
export const getUniqueId = () => {
  const array = new Uint32Array(4);
  crypto.getRandomValues(array);

  const part1 = array[0].toString(16).padStart(8, "0");
  const part2 = array[1].toString(16).padStart(8, "0");
  const part3 = "4" + array[2].toString(16).substr(1, 3).padStart(3, "0");
  const part4 = ((array[3] & 0x0fff) | 0x4000).toString(16).padStart(4, "0");
  const part5 = ((array[3] & 0x3fff) | 0x8000).toString(16).padStart(4, "0");

  return `${part1}-${part2}-${part3}-${part4}-${part5}`;
};

/**
 * @desc check does it dev mode or live mode
 * it return false only if its a production build
 */
export const isDev = () => {
  if (process.env.NODE_ENV === "development") {
    return true;
  }
  return false;
};

/**
 * @desc get query params
 */
export const getUrlParams = (queryParams) => {
  if (!queryParams) return new URLSearchParams();
  return new URLSearchParams(queryParams);
};

/**
 * @desc get query param by name
 */
export const getUrlParam = (query, name) => {
  let queryParams = new URLSearchParams();
  if (query) queryParams = new URLSearchParams(query);
  return queryParams.get(name);
};

/**
 * @desc get user friendly string from the given value
 * @param {*} value
 * @param {*} replaceChar
 */
export const UserFriendlyString = (value, replaceChar) => {
  if (!value) return "";
  value = value.trim();

  if (!replaceChar) replaceChar = "_";
  return value === undefined
    ? ""
    : value
        .replace(/[^a-z0-9_]+/gi, replaceChar)
        .replace(/^-|-$/g, "")
        .toLowerCase();
};

/**
 * @desc Checks if given value is Boolean
 * @param {*} value // Accepts string
 */
export const isBoolean = (value) => {
  if (typeof value === "boolean") {
    return true;
  } else {
    return false;
  }
};

export const stringToBoolean = (value) => {
  if (!value) return false;

  switch (value.toString().toLowerCase().trim()) {
    case "true":
    case "yes":
    case "1":
      return true;
    case "false":
    case "no":
    case "0":
    case null:
      return false;
    default:
      return Boolean(value);
  }
};

export const appEnvironments = () => {
  if (isProduction()) return "inDevlopment";
  return null;
};

export const isProduction = () => {
  try {
    let url = window.location.href;
    if (url && url.includes("docfliq.com")) return false;
  } catch (ex) {}

  if (!isDev()) return true;
  return false;
};

export function mathRound(number, digit = 2) {
  try {
    if (Number(number) < 1) digit = 3;
    if (number) return Number(number).toFixed(digit);
  } catch (e) {}
  return Number(0).toFixed(2);
}

/**
 * @desc load java script async from code
 */
export const loadJavaScript = (url) => {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
  return script;
};

/**
 * @desc get formatted date
 */
export const getFormattedDate = (date) => {
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let year = date.getFullYear();
  return day + "/" + month + "/" + year;
};

export const getFormattedTime = (date) => {
  if (!date) date = new Date();
  else date = new Date(date);
  let hour = date.getHours();
  let minutes = date.getMinutes();
  const time =
    String(hour).padStart(2, "0") + ":" + String(minutes).padStart(2, "0");
  return String(time);
};

export const removeWhiteSpaceRegex = (str) => {
  return str.replace(/ +/g, "");
};

export const replaceWhiteSpaceWithDash = (str) => {
  return str.replace(/\s+/g, "-");
};

export const replaceWhiteSpaceWithUnderscore = (str) => {
  return str.replace(/\s+/g, "_");
};

export const getAPIResponseError = (e) => {
  if (e) {
    if (e.response && e.response.data) {
      let code = e.response.data?.status || e.response?.status;
      let msg = e.response.data?.message;

      return {
        code,
        message: msg,
        error: e,
      };
    }
  }
};

export const formatCurrency = (num) => {
  try {
    if (num) {
      // Using toLocaleString for formatting as currency
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  } catch (e) {
    console.error("Error formatting currency:", e);
  }
  return num;
};

export const currencyWithDecimal = (num) => {
  let returnValue = num;
  try {
    let digit = 2;
    if (num) {
      if (Number(num) < 1) digit = 3;
      if (Number(num) > 999) digit = 1;
      const num2 = Number(num).toFixed(digit);
      returnValue = formatCurrency(num2);
    } else {
      returnValue = Number(0).toFixed(digit);
    }
  } catch (e) {}
  return returnValue;
};

export const numToWords = (num) => {
  let a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  let b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  if ((num = num.toString()).length > 9) return "overflow";
  let n = num.split("");
  n = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return "";
  let str = "";
  str +=
    n[1] !== "00"
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
      : "";
  str +=
    n[2] !== "00"
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
      : "";
  str +=
    n[3] !== "00"
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
      : "";
  str +=
    n[4] !== "00"
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
      : "";
  str +=
    n[5] !== "00"
      ? (str !== "" ? "and " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
        ""
      : "";
  return str;
};

export const currencyInWords = (value) => {
  if (isEmpty(value)) return "";
  let number = parseFloat(value);
  if (number === undefined) return "";
  let num = value.toString().split(".");

  let Rs = numToWords(num[0]).toUpperCase();
  if (num.length === 1) return Rs + " RUPEES ONLY";

  //Get two digit decimal
  let num2 = (num[1] + "0").substring(0, 2);
  if (num2[0] === "0") num2 = num2[1];

  let Paisa = numToWords(num2).toUpperCase();
  return Rs + " RUPEES AND " + Paisa + " PAISA ONLY";
};

export const getDateDifference = (startDate, endDate) => {
  try {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays >= 0) return diffDays;
  } catch (e) {}
};

export const isPastDate = (date) => {
  if (!date) {
    return false; // Assuming you want to return a boolean for undefined or null dates
  }

  const diff = moment().diff(date, "days");
  return diff > 0;
};

export const displayRelativeDate = (date) => {
  if (!date) {
    return "";
  }
  let diff = moment().diff(date, "days");
  if (diff === 0) {
    return "Today";
  } else if (diff === -1) {
    return "Tomorrow";
  } else {
    return moment(date).fromNow();
  }
};

export const getDateString = (date) => {
  //return yyyyMMdd
  if (date) {
    date = new Date(date);
    return (
      date.getFullYear() +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      ("0" + date.getDate()).slice(-2)
    );
  }
};

export const getDateFromFormattedDate = (date) => {
  //parse yyyyMMdd and return date object
  if (date) {
    let year = Number(date.substr(0, 4));
    let month = Number(date.substr(4, 2));
    let day = Number(date.substr(6, 2));
    //here do -1 becuase month is always +1
    let result = new Date(year, month - 1, day);
    return result;
  }
  return;
};

export const getUTCDate = (date) => {
  if (date) date = new Date(date);
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
};

export const getDate = (date) => {
  if (!date) date = new Date();
  date = moment(date).format(DATE_FORMAT);
  return date;
};

export const removeDuplicates = (data, key) => {
  return [...new Map(data.map((x) => [key(x), x])).values()];
};
export const durationInAsSeconds = (start, end) => {
  start = moment(start);
  end = moment(end);
  const duration = moment.duration(end.diff(start));
  return duration.asSeconds();
};

export const convertToWebP = (inputFile, newfileName, resolution, callback) => {
  //getting File Actual Path
  inputFile = URL.createObjectURL(inputFile);

  let cnv = document.createElement("canvas");
  let ctx = cnv.getContext("2d");
  let img = new Image();
  img.src = inputFile;
  img.onload = function () {
    cnv.width = img.width;
    cnv.height = img.height;
    ctx.drawImage(img, 0, 0);
    let data = "";
    switch (resolution) {
      case "high":
        data = cnv.toDataURL("image/webp");
        break;

      case "medium":
        data = cnv.toDataURL("image/webp", 0.7);
        break;

      case "low":
        data = cnv.toDataURL("image/webp", 0.4);
        break;

      default:
        data = cnv.toDataURL("image/webp");
        break;
    }
    // let data = cnv.toDataURL('image/webp',0.50); //second param is quality percentage.
    let output = data.replace(/^data:image\/(png|jpg);base64,/, "");

    //base64 to original file
    let arr = output.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    callback(output, new File([u8arr], newfileName, { type: mime }));
  };
};

export const currentDateTimeForFile = () => {
  try {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    const formattedTime = `${currentDate
      .getHours()
      .toString()
      .padStart(2, "0")}-${currentDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;

    return `${formattedDate}-${formattedTime}`;
  } catch (error) {
    throw error;
  }
};

export const convertToIndianTime = (utcTimestamp) => {
  return new Date(utcTimestamp).toLocaleString(undefined, {
    timeZone: "Asia/Kolkata",
  });
};

export const truncateSentence = (sentence, maxLength) => {
  if (!sentence) {
    return "";
  }

  // Remove leading and trailing HTML tags, if any
  const cleanedSentence = sentence.replace(/<[^>]*>/g, "");

  // Check if the cleaned sentence length is less than or equal to maxLength
  if (cleanedSentence.length <= maxLength) {
    return cleanedSentence;
  }

  // Truncate the cleaned sentence and append an ellipsis
  const truncated = cleanedSentence.substring(0, maxLength).trim();
  return truncated + "...";
};

export const formatTimestampForVideo = (timestamp, target_country) => {
  const target_timezone = countriesUtility.getCountryTimezones(target_country);
  let convertedToTimezone = dateUtility.convertISTTimestampToUserTimezone(
    timestamp,
    target_timezone
  );
  const date = new Date(convertedToTimezone);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert hours to 12-hour format

  const formattedDate = `${monthNames[monthIndex]} ${day}, ${year} ${hours}:${
    minutes < 10 ? "0" + minutes : minutes
  } ${ampm}`;

  return formattedDate;
};

export const customFormatTimestampForVideo = (timestamp, target_country) => {
  const target_timezone = countriesUtility.getCountryTimezones(target_country);
  let convertedToTimezone = dateUtility.convertISTTimestampToUserTimezone(
    timestamp,
    target_timezone
  );
  const date = new Date(convertedToTimezone);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
// let hours = date.getHours();
  // const minutes = date.getMinutes();
  // const ampm = hours >= 12 ? "PM" : "AM";
  // hours = hours % 12 || 12; // Convert hours to 12-hour format

  const formattedDate = `${monthNames[monthIndex]} ${day}, ${year}`;

  return formattedDate;
};

/**
 * Removes HTML tags from a given string.
 *
 * @param {string} input - The string from which HTML tags need to be removed.
 * @returns {string} - The resulting string after removing HTML tags.
 */
export function escapeHtml(input) {
  if (!input) return;
  // Remove HTML tags
  input = input.replace(/<[^>]*>/g, "");

  return input;
}