import React, { useEffect, useRef } from "react";
import { Player } from "theoplayer";

import '/node_modules/theoplayer/ui.css';

const PLAYER_LICENSE =
    "sZP7IYe6T6fkTuggTSaL0mk6CLbiFSxg3Kf-TDx636ztIlbk3ofz3l5k3lf6FOPlUY3zWokgbgjNIOf9fl5_3oC_0SboFDB_3Df-3QXg3Zz_3LftFDarTQhLClbcIQao0OfVfK4_bQgZCYxNWoryIQXzImf90lhk3l5r0LCi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3l5t0SRkTuarTubcFOPeWok1dDrLYtA1Ioh6TgV6v6PUFOPeWok1dDrLYt3qUYPlImf9DZfJfgzVfG3edt06TgV6dwx-Wuh6Ymi6bo4pIXjNWYAZIY3LdDjpflNKCDcLIYz";


const TheoPlayerReact = ({
    source,
    relatedContent,
    onPlay,
    onPause,
    onAdbegin,
    onLoadedMetaData,
    onProgress,
    onVolumeChange,
    onError,
    onEnded,
    onPlaying,
    onSeek,
    // setPlayer,
    onTimeUpdate,
    // autoPlay,
    currentTime,
    video
}) => {
    const playerRef = useRef(null);
    const theoPlayerRef = useRef(null);

    // const socket = io(WEBSOCKET_URL); // Connect to your socket server

    useEffect(() => {
        const initializeComponent = async () => {
            if (playerRef.current) {
                const createPlayer = () => {

                    if (playerRef.current) {
                        const player = new Player(playerRef.current, {
                            libraryLocation: "/scripts/",
                            license: PLAYER_LICENSE,
                            ui: {
                                fluid: true,
                            },
                        });
            
                        player.preload = "auto";
                        player.autoplay = true;
            
                        player.source = {
                            sources: [
                                {
                                    src: 'https://ws-cdn.wstream.net/archive/docfliq/Nutrition_Masterclass/jeffery.smil/playlist.m3u8',
                                    type: 'application/x-mpegurl',
                                },
                            ],
                        };
            
                        // window.theoplayerWowza.registerPlayer(player);
                        window.theoplayer = player;
            
                        player.currentTime = currentTime || 0;
            
                        window.parent.theoplayer = player;
                        theoPlayerRef.current = player;
            
                    }
                };
                createPlayer();
            }
        };

        initializeComponent();

        return () => {
            if (theoPlayerRef.current) {
                try {
                    theoPlayerRef.current.source = "";
                } catch (error) {
                    // Handle error (e.g., log it or display a message to the user)
                    console.error("Error destroying THEOplayer instance:", error);
                }
            }
        };
    }, []);

    const onDoubleClick = () => {
        toggleFullScreen();
    };


    const toggleFullScreen = () => {
        if (window.theoplayer?.presentationMode === "fullscreen") {
            window.theoplayer.presentation.requestMode("inline");
        } else {
            window.theoplayer.presentation.requestMode("fullscreen");
        }
    };

    

    return (
        <div className="w-full h-5" id="video">
            <div
                id="theoplayer-container"
                className="theoplayer-container video-js theoplayer-skin vjs-16-9 THEOplayer"
                ref={playerRef}
                onDoubleClick={onDoubleClick}
            >
                <div className="theoplayer-overlay-element-container">
                    <div className="overlay-container">
                        <iframe id="poll_overlay" name="poll_overlay"
                            // src="blank.htm"
                            src="https://docspace.wstream.net/polls/poll1/"
                            // src="/polls/66fa918e111803c3812b259c"
                            frameBorder="0" style={{ width: "100%", height: "100%", top: 0, left: 0, position: "absolute", }} title="Docfliq - Poll">

                        </iframe>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default TheoPlayerReact;