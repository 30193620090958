import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enEn from "./locales/en.json";
import arAr from "./locales/ar.json";

import enPrivacyPolicy from "./locales/en/privacy-policy.json";

import arChannels from "./locales/ar/channels.json";
import arVideos from "./locales/ar/videos.json";
import arExperts from "./locales/ar/experts.json";
import arSpecialties from "./locales/ar/specialties.json";
import arPromotionVideos from "./locales/ar/promotion_videos.json";
import arAssessmentQuestions from "./locales/ar/assessment_questions.json";
import arAssessmentQuestionOptions from "./locales/ar/assessment_questions_options.json";
import arAssessmentTitle from "./locales/ar/assessment_title.json";
import arErrorMessages from "./locales/ar/error_messages.json";
import arFaqQuestions from  "./locales/ar/faq-questions.json";
import saudiArabiaStatesCities from "./locales/ar/saudi-arabia-states-cities.json"
import { I18_NEXT_LANGUAGE_KEY } from "../global/constants/app-constants";

const defaultLanguage = "en";

export const defaultNamespace = "default";


// Define your locales with directionality
const locales = [
  {
    code: 'ar',
    name: 'Arabic',
    dir: 'rtl'
  },
  {
    code: 'en',
    name: 'English',
    dir: 'ltr'
  },
  // Add more locales as needed
];

// Function to merge translations
const mergeTranslations = (...translations) => {
  return translations.reduce((acc, translation) => {
    return { ...acc, ...translation };
  }, {});
};

export const resources = {
  en: {
    [defaultNamespace]: mergeTranslations(enEn, enPrivacyPolicy),
  },
  ar: {
    [defaultNamespace]: mergeTranslations(
      arAr,
      arChannels,
      arVideos,
      arExperts,
      arSpecialties,
      arPromotionVideos,
      arAssessmentQuestions,
      arAssessmentQuestionOptions,
      arAssessmentTitle,
      arErrorMessages,
      arFaqQuestions,
      saudiArabiaStatesCities
    ),
  },
};

// Get the saved language from localStorage or default to the defaultLanguage
const savedLanguage =
  localStorage.getItem(I18_NEXT_LANGUAGE_KEY) || defaultLanguage;

i18n.use(initReactI18next).init({
  defaultNS: defaultNamespace,
  ns: [defaultNamespace],
  resources,
  lng: savedLanguage,
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
  i18n: {
    locales: locales,
    defaultLocale: defaultLanguage, // Default language
  },
});